<template>
  <div>
    <commonDetail></commonDetail>
  </div>
</template>

<script>
import commonDetail from './components/commonDetail.vue'
import { testResultDetail } from '@/api/examination'
import to from 'await-to'
export default {
  components: {
    commonDetail,
  },
  data() {
    return {
      id: this.$route.query.id,
    }
  },
  created() {
    this.testResultDetailData()
  },
  methods: {
    async testResultDetailData() {
      const [res, err] = await to(testResultDetail({ id: this.id }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.classList = res.data
    },
  },
}
</script>

<style scoped></style>
